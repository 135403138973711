@import '../../../styles/utils/variables';

.search-form {
  .ant-select.ant-select-open .ant-select-arrow {
    transform: rotate(180deg) !important;
    transition: all 0.4s;
  }
  .ant-select .ant-select-arrow {
    transition: all 0.4s;
    margin-right: 6px;
  }
  .ant-select.ant-select-in-form-item {
    &.select-ais  {
      height: 69px;
      .ant-select-selector {
        // height: 69px;
        padding: 15px 25px;
        // color: @mainDark;
        // font-size: 14px;
        background-color: transparent;
        border-radius: 12px 0 0 12px;
        border: none;
        border-right: 1px solid #DEDEE4 !important;
        width: 100%;
        &:hover {
          box-shadow: 0 0px 8px rgba(22, 21, 21, 0.1);
        }

        .ant-select-selection-placeholder {
          color: @mainDark;
        }
      }
      .ant-select-selection-item {
        background: transparent;
      }
    }
    &.select-subcategory {
      height: 69px;
      .ant-select-selector {
        padding: 15px 20px;
        background-color: transparent;
        border-radius: 0;
        border: none;
        border-right: 1px solid #DEDEE4 !important;
        width: 100%;
        @media (max-width: 768px) {
          border-right: none !important;
        }
        &:hover {
          box-shadow: 0 0 8px rgba(22, 21, 21, 0.1);
        }
        .ant-select-selection-placeholder {
          color: @mainDark;
        }
      }
      .ant-select-selection-item {
        background: transparent;
      }
    }
  }

  .ant-input-affix-wrapper.input-search {
    height: 69px;
    padding: 25px 20px;
    border-radius: 0 12px 12px 0;
    background-color: transparent;
    border: none;
    @media (max-width: 768px) {
      border: 1px solid #DEDEE4;
      border-radius: 12px;
    }
    .ant-input {
      background-color: transparent;
      color: @mainDark;
      &::-webkit-input-placeholder {
        color: @mainDark;
      }
    }
  }

  .ant-select.ant-select-in-form-item {
    &.select-round  {
      height: 44px;
      .ant-select-selector {
        padding: 2px 18px;
        // color: @mainDark;
        // font-size: 14px;
        background-color: transparent;
        border-radius: 50px;
        border: 1px solid #DEDEE4 !important;
        width: 100%;
        // &:hover {
        //   box-shadow: 0 0px 8px rgba(22, 21, 21, 0.1);
        // }

        .ant-select-selection-placeholder {
          color: @mainDark;
        }
      }
      .ant-select-selection-item {
        background: transparent;
      }
    }
  }

  .clear-form-wrapper {
    @media (max-width: 992px) {
      position: absolute;
      bottom: 60px;
      right: 0px;
    }
  }

  .ant-btn.ant-btn-link.clear-btn:disabled {
    opacity: 0.5;
  }
}