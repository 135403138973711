@import '../../../../styles/utils/variables';

.banner-wrapper {
  margin-top: 20px;
  border: 1px solid @gray4;
  border-radius: 12px;
  height: 240px;
  &.first {
    background: #F0F1FF;
  }
  &.second {
    background: #788DE8;
  }
  &.third {
    background: linear-gradient(90deg, #6595F3 0%, #B771C2 48%, #FF3A9B 100%);
  }
  &.four {
    // background-image: url('../../../Images/catalog-page/banner_4.svg');
    // background-size: 30%;
    // background-repeat: no-repeat;
    // background-position: 62%;
    background: linear-gradient(90deg, #6595F3 0%, #B771C2 48%, #FF3A9B 100%), url('../../../Images/catalog-page/banner_4.svg');
    background-size: cover;
    background-blend-mode: overlay;
    background-size: auto 100%;
    background-position: 62%;
    background-repeat: no-repeat;
  }

  // .fst-col-wrapper {
  //   display: flex;
  //   justify-content: center;
  //   padding: 46px 26px;
  //   // border-right: 1px solid #DEDEE4;
  // }
  .second-col-wrapper {
    display: flex;
    flex-direction: column;
    padding: 23px 40px 23px 80px;
    // border: 1px solid red;

    .banner-gradient-text {
      // font-weight: 400;
      &.first {
        margin: 0 0 0 80px;
        background-clip: text;
        background: linear-gradient(52deg, #3da3fc 0%, #a685d2 56.77%, #fc1d7f 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}