@import '../../../../styles/utils/variables';

.footer {
  .footer-text-item {
    display: block;
    color: @mainDark;
    font-size: 16px;
    margin-top: 16px;
    &:hover {
      cursor: pointer;
    }
  }
  .footer-title-item {
    color: @mainDark;
    font-size: 22px;
    font-weight: 400;
  }

  .footer-link {
    &:hover {
      cursor: pointer;
    }
  }

  .text-block {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Ограничение на количество строк */
    -webkit-box-orient: vertical;
  }
}