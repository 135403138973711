.trans {
  transition: all 0.3s ease;
}
@keyframes growUp {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes border-pulsate {
  0% {
    border-color: #237cfb;
  }
  50% {
    border-color: #f2f3f8;
  }
  100% {
    border-color: #237cfb;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes pulseBig {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
  from {
    bottom: -250px;
  }
  to {
    bottom: 0;
  }
}
@keyframes mini-slide {
  from {
    bottom: -224px;
  }
  to {
    bottom: 0;
  }
}
@keyframes slide-modal {
  0% {
    transform: translateY(100%) scale(1);
    bottom: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    bottom: 0;
  }
}
@keyframes slide-height {
  0% {
    height: 0;
  }
  100% {
    height: 230px;
  }
}
@keyframes marquee-infinite {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
ul,
li {
  margin: 0;
  padding: 0;
}
/* BG/Dark */
/* ----  Neutral -------*/
/* ----  Blue -------*/
/* ----  Blue -------*/
/* ----  Red -------*/
/* ----  Pink -------*/
/* ----  Green -------*/
/* ----  Purple -------*/
/* ----  Brown -------*/
/* ----  Yellow -------*/
/* ---- Media ----*/
/* ==================
 * COLOUR THEME MIXIN
 * ================== */
/* ----------- Typography -----------*/
.header-x-large-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -1px;
}
.heading-large-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.69px;
}
.heading-medium-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: -0.53px;
}
.heading-small-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */
  letter-spacing: -0.4px;
}
.body-bold-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.18px;
}
.body-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.18px;
}
.sub-heading-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: -0.09px;
  text-transform: uppercase;
}
.button-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: -0.09px;
}
.caption-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
}
.caption-small-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */
}
.mini-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  /* or 150% */
  letter-spacing: 0.01px;
}
.drop-shadow {
  box-shadow: 0 4px 16px rgba(0, 102, 255, 0.06);
}
.theme-dark .border {
  border: 0.5px solid #0f2543;
}
.theme-light .border {
  border: 0.5px solid #F2F4F7;
}
.border-radius {
  border-radius: 4px;
}
.no-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}
/* ----------- Buttons -----------*/
.btn-x-large {
  transition: all 0.3s ease;
  display: inline-block;
  max-width: 324px;
  width: 100%;
  height: 48px;
  color: #fff;
  text-align: center;
  border: none;
  cursor: pointer;
  background-color: #237cfb;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: -0.09px;
  border-radius: 4px;
}
@media only screen and (max-width: 767px) {
  .btn-x-large {
    height: 72px;
  }
}
/* ----------- Buttons -----------*/
.flex-row-space-between {
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
}
.flex-row-stretch {
  display: flex;
  flex-flow: nowrap row;
  justify-content: stretch;
}
.skeleton-animation-bg {
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.theme-dark .skeleton-animation-bg {
  background-image: linear-gradient(90deg, #0f2543 20%, #0d1a2f 37%, #0f2543 63%);
}
.theme-light .skeleton-animation-bg {
  background-image: linear-gradient(90deg, #F2F4F7 20%, #fff 37%, #F2F4F7 63%);
}
.skeleton-animation-red {
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.theme-dark .skeleton-animation-red {
  background-image: linear-gradient(90deg, #ff4b40 20%, #ffc3bf 37%, #ff4b40 63%);
}
.theme-light .skeleton-animation-red {
  background-image: linear-gradient(90deg, #ff4b40 20%, #ffc3bf 37%, #ff4b40 63%);
}
.skeleton-animation-blue {
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.theme-dark .skeleton-animation-blue {
  background-image: linear-gradient(90deg, #237cfb 20%, #bbd7ff 37%, #237cfb 63%);
}
.theme-light .skeleton-animation-blue {
  background-image: linear-gradient(90deg, #237cfb 20%, #bbd7ff 37%, #237cfb 63%);
}
.ant-message .ant-message-notice .ant-message-notice-content .ant-message-error .anticon {
  top: -2px !important;
}
p {
  margin: 0;
  padding: 0;
}
