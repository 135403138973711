@import '../../../styles/utils/variables';
@import '../../../styles/theme/theme';

.login-form {
  .ant-input, .ant-input-affix-wrapper{
   border-radius: 3px;
  }

  button[type='submit'] {
    border-radius: 3px;
  }

  .ant-form-item-explain-error{
    display: none;
  }

  &.restore {
    .ant-form-item {
      margin-bottom: 32px;
    }
  }
}

.search-form{
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }

  .radio-btn-group{
    width: 100%;
    display: flex;
    // justify-content: space-between;

      > .ant-radio-button-wrapper{
        padding: 0 12px;
        border-radius: 6px;
        border: none;
        border-inline-start-width: 1px;
        background: #EAECF0;
        width: 88px;
        text-align: center;
        margin-right: 12px;

        &-checked{
          color: #344054;
          &:hover {
            // color:  #e8317b;
          }
        }

        &:before{
          display: none;
        }

        .ant-radio-button{
          &-checked{
            border-radius: 5px;
            background: #D0D5DD;
          }
        }
      }

  }

  .ant-picker, .ant-select .ant-select-selector{
    background: #EAECF0;
    color: #344054;
  }

  .ant-select-selection-item{
    background: #D0D5DD;
  }
}

@media only screen and (max-width: 767px) {
  .ant-form.ant-form-horizontal {
    width: 100%;
  }

  .login-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    button[type='submit'] {
      height: 72px;
      order: 1;
      margin-top: auto;
    }
    &.restore {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

// .ant-select .ant-select-clear {
//   background: transparent;
// }
