@import '../utils/variables';

/* ==================
 * COLOUR THEME MIXIN
 * ================== */
@library: {
  @dark: {
    color-white-to-dark: @dark;
    color-dark-to-white: @white;
    defatult-border-color: @blue900;

    fill-color: @blue600;
    menu-fill: @Neutral200;

    // theme-switcher
    theme-switcher-text-color-default: @blue600;
    theme-switcher-text-color-active: @white;
    theme-switcher-border-color: @blue700;

    //notifications
    notif-background-color: @background;
    notif-border-color: @blue900;
    notif-gradient-bottom: @darkOffsetBottom;
    notif-gradient-top: @darkOffsetTop;
    notif-header-text: @Neutral200;
    notif-fill-color: @Neutral100;

    notif-empty-text-color: @blue700;
    notif-empty-icon-color: @blue700;

    notif-item-text-color: @Neutral200;
    notif-item-time-text-color: @Neutral800;
    notif-item-border-color: @blue900;

    //scroll
    scroll-track-backgound: @background;
    scroll-thumb-backgound: @blue500;

    //sidebar
    sidebar-border: @blue900;
    sidebar-link-bgc: @blue900;
    sidebar-link: @Neutral500;
    sidebar-link-active: @blue500;
    sidebar-fill-outline: @Neutral500;
    sidebar-fill-bold: @blue500;

    sidebar-sticky-bgc: @background;
    sidebar-sticky-brc: @blue900;

    //skeleton
    skeleton-bg: linear-gradient(90deg, @blue900 20%, @dark 37%, @blue900 63%);
    skeleton-red: linear-gradient(90deg, @red500 20%, @red200 37%, @red500 63%);
    skeleton-blue: linear-gradient(90deg, @blue500 20%, @blue200 37%, @blue500 63%);

    scroll-gradient-top: linear-gradient(@dark, rgba(13, 26, 47, 0.25));
    scroll-gradient-bottom: linear-gradient(rgba(13, 26, 47, 0.25), @dark);

    mobile-navigation-gradient: linear-gradient(180deg, rgba(13, 26, 47, 0), @dark);
    locked-gradient: linear-gradient(360deg, @dark 0%, rgba(13, 26, 47, 0) 102.78%);

    black-to-white: #ffffff;
    white-to-black: #000000;
    white-to-blue-900: @blue900;

    neutral-100-to-800: @Neutral800;
    neutral-100-to-900: @Neutral900;
    neutral-200-to-400: @Neutral400;
    neutral-200-to-500: @Neutral500;
    neutral-200-to-700: @Neutral700;
    neutral-200-to-800: @Neutral800;
    neutral-300-to-500: @Neutral500;
    neutral-300-to-600: @Neutral600;
    neutral-300-to-700: @Neutral700;
    neutral-300-to-800: @Neutral800;
    neutral-400-to-500: @Neutral500;
    neutral-400-to-600: @Neutral600;
    neutral-500-to-200: @Neutral200;
    neutral-500-to-100: @Neutral100;
    neutral-500-to-400: @Neutral400;
    neutral-500-to-600: @Neutral600;
    neutral-800-to-200: @Neutral200;
    neutral-800-to-300: @Neutral300;
    neutral-800-to-500: @Neutral500;
    neutral-800-to-700: @Neutral700;
    neutral-800-to-900: @Neutral900;

    neutral-100-to-blue-800: @blue800;
    neutral-100-to-blue-700: @blue700;
    neutral-100-to-blue-900: @blue900;
    neutral-100-to-blue-500: @blue500;
    neutral-200-to-blue-600: @blue600;
    neutral-200-to-blue-700: @blue700;
    neutral-200-to-blue-800: @blue800;
    neutral-200-to-blue-900: @blue900;
    neutral-300-to-blue-700: @blue700;
    neutral-300-to-blue-500: @blue500;
    neutral-300-to-blue-800: @blue800;
    neutral-300-to-blue-600: @blue600;
    neutral-400-to-blue-600: @blue600;
    neutral-400-to-blue-700: @blue700;
    neutral-500-to-blue-600: @blue600;
    neutral-500-to-blue-700: @blue700;
    neutral-800-to-blue-500: @blue500;
    neutral-800-to-blue-600: @blue600;

    neutral-100-to-red-900: @red900;
    neutral-500-to-red-600: @red600;
    neutral-100-to-yellow-900: @yellow900;
    neutral-500-to-yellow-600: @yellow600;
    neutral-100-to-pink-900: @pink900;
    neutral-500-to-pink-600: @pink600;

    blue-100-to-dark: @dark;
    blue-200-to-neutral-800: @Neutral800;

    blue-100-to-900: @blue900;
    red-100-to-900: @red900;
    yellow-100-to-900: @yellow900;
    pink-100-to-900: @pink900;
    green-100-to-900: @green900;
    purple-100-to-900: @purple900;
    brown-100-to-900: @brown900;

    blue-200-to-600: @blue600;
    red-200-to-600: @red600;
    yellow-200-to-600: @yellow600;
    pink-200-to-600: @pink600;
    green-200-to-600: @green600;
    purple-200-to-600: @purple600;
    brown-200-to-600: @brown600;

    blue-200-to-700: @blue700;
    blue-200-to-800: @blue800;
    blue-500-to-400: @blue400;

    red-200-to-700: @red700;
    yellow-200-to-700: @yellow700;
    pink-200-to-700: @pink700;
    green-200-to-700: @green700;
    purple-200-to-700: @purple700;
    brown-200-to-700: @brown700;
  };

  @light: {
    color-white-to-dark: @white;
    color-dark-to-white: @dark;
    defatult-border-color: @Neutral100;

    fill-color: @Neutral500;
    menu-fill: @Neutral800;

    // theme-switcher
    theme-switcher-text-color-default: @Neutral500;
    theme-switcher-text-color-active: @white;
    theme-switcher-border-color: @Neutral100;

    //notifications
    notif-background-color: @white;
    notif-border-color: @Neutral100;
    notif-gradient-bottom: @neutralOffsetBottom;
    notif-gradient-top: @neutralOffsetTop;
    notif-header-text: @Neutral800;
    notif-fill-color: @Neutral500;
    notif-empty-text-color: @Neutral400;
    notif-empty-icon-color: @Neutral400;

    notif-item-text-color: @Neutral800;
    notif-item-time-text-color: @Neutral300;
    notif-item-border-color: @Neutral100;

    //scroll
    scroll-track-backgound: @Neutral100;
    scroll-thumb-backgound: @blue500;

    //sidebar
    sidebar-fill-outline: @Neutral500;
    sidebar-fill-bold: @blue500;
    sidebar-border: @Neutral100;
    sidebar-link-bgc: @blue100;
    sidebar-link: @Neutral500;
    sidebar-link-active: @blue500;
    sidebar-sticky-bgc: @white;
    sidebar-sticky-brc: @Neutral100;

    //skeleton
    skeleton-bg: linear-gradient(
      90deg,
      @Neutral100 20%,
      @white 37%,
      @Neutral100 63%
    );
    skeleton-red: linear-gradient(90deg, @red500 20%, @red200 37%, @red500 63%);
    skeleton-blue: linear-gradient(
      90deg,
      @blue500 20%,
      @blue200 37%,
      @blue500 63%
    );

    scroll-gradient-top: linear-gradient(@white, rgba(255, 255, 255, 0.25));
    scroll-gradient-bottom: linear-gradient(rgba(255, 255, 255, 0.25), @white);
    locked-gradient: linear-gradient(360deg, @white 0%, rgba(255, 255, 255, 0) 102.78%);

    mobile-navigation-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0), @white);

    black-to-white: #000000;
    white-to-black: #ffffff;
    white-to-blue-900: #ffffff;

    neutral-100-to-800: @Neutral100;
    neutral-100-to-900: @Neutral100;
    neutral-200-to-400: @Neutral200;
    neutral-200-to-500: @Neutral200;
    neutral-200-to-700: @Neutral200;
    neutral-200-to-800: @Neutral200;
    neutral-300-to-500: @Neutral300;
    neutral-300-to-600: @Neutral300;
    neutral-300-to-700: @Neutral300;
    neutral-300-to-800: @Neutral300;
    neutral-400-to-500: @Neutral400;
    neutral-400-to-600: @Neutral400;
    neutral-500-to-200: @Neutral500;
    neutral-500-to-100: @Neutral500;
    neutral-500-to-400: @Neutral500;
    neutral-500-to-600: @Neutral500;
    neutral-800-to-200: @Neutral800;
    neutral-800-to-300: @Neutral800;
    neutral-800-to-500: @Neutral800;
    neutral-800-to-700: @Neutral800;
    neutral-800-to-900: @Neutral800;

    neutral-100-to-blue-800: @Neutral100;
    neutral-100-to-blue-700: @Neutral100;
    neutral-100-to-blue-900: @Neutral100;
    neutral-100-to-blue-500: @Neutral100;
    neutral-200-to-blue-600: @Neutral200;
    neutral-200-to-blue-800: @Neutral200;
    neutral-200-to-blue-900: @Neutral200;
    neutral-200-to-blue-700: @Neutral200;
    neutral-300-to-blue-600: @Neutral300;
    neutral-300-to-blue-500: @Neutral300;
    neutral-300-to-blue-700: @Neutral300;
    neutral-300-to-blue-800: @Neutral300;
    neutral-400-to-blue-600: @Neutral400;
    neutral-400-to-blue-700: @Neutral400;
    neutral-500-to-blue-600: @Neutral500;
    neutral-500-to-blue-700: @Neutral500;
    neutral-800-to-blue-500: @Neutral800;
    neutral-800-to-blue-600: @Neutral800;

    neutral-100-to-red-900: @Neutral100;
    neutral-500-to-red-600: @Neutral500;
    neutral-100-to-yellow-900: @Neutral100;
    neutral-500-to-yellow-600: @Neutral500;
    neutral-100-to-pink-900: @Neutral100;
    neutral-500-to-pink-600: @Neutral500;

    blue-100-to-dark: @blue100;
    blue-200-to-neutral-800: @blue200;

    blue-100-to-900: @blue100;
    red-100-to-900: @red100;
    yellow-100-to-900: @yellow100;
    pink-100-to-900: @pink100;
    green-100-to-900: @green100;
    purple-100-to-900: @purple100;
    brown-100-to-900: @brown100;

    blue-200-to-600: @blue200;
    red-200-to-600: @red200;
    yellow-200-to-600: @yellow200;
    pink-200-to-600: @pink200;
    green-200-to-600: @green200;
    purple-200-to-600: @purple200;
    brown-200-to-600: @brown200;

    blue-200-to-700: @blue200;
    blue-200-to-800: @blue200;
    blue-500-to-400: @blue500;

    red-200-to-700: @red200;
    yellow-200-to-700: @yellow200;
    pink-200-to-700: @pink200;
    green-200-to-700: @green200;
    purple-200-to-700: @purple200;
    brown-200-to-700: @brown200;
  };
};
