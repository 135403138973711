@import '../../../../styles/utils/variables';

.company-page {

  // .fst-col-wrapper {
  // }
  .second-col-wrapper {
    padding-bottom: 20px;
  }

  .page-titlle {
    font-weight: 500;
    font-size: 30px;

    &.company-name {
      margin-top: 36px;
    }
    &.section-title {
      margin-top: 70px;
      margin-bottom: 46px;
      &.overview {
        margin-top: 60px;
      }
      &.overview-sub-title {
        font-weight: 400;
        font-size: 22px;
        margin-top: 40px;
        margin-bottom: 35px;
      }
    }
    &.top-rated-card-title {
      font-weight: 300;
      font-size: 22px;
      margin: 0;
      @media (max-width: 1300px) {
        font-size: 18px;
      }
    }
  }

  .ant-tabs.company-tabs  {
    margin-top: 30px;
    margin-left: -161px;
    margin-right: -161px;
    @media (max-width: 1470px) {
      margin-left: -6vw;
      margin-right: -6vw;
    }

    .ant-tabs-nav-list {
      transform: translate(161px, 0px) !important;
      @media (max-width: 1470px) {
        transform: translate(6vw, 0px) !important;
      }
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @mainDark;
  }
  .ant-tabs.ant-tabs-top.company-tabs .ant-tabs-tab {
    width: clamp(7.91rem, 6.5049rem + 5.9951vw, 12.5rem);
    justify-content: center;
    @media (max-width: 800px) {
      width: fit-content;
    }
    .ant-tabs-tab-btn {
      // color: @Sales500 !important;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .ant-tabs.ant-tabs-tab.company-tabs {
    margin: 0 56px!important;
  }
  .ant-tabs.company-tabs {
    .ant-tabs-nav {
      background: #fff;
      // border-top: 1px solid #EAECF0;
      // border-bottom: 1px solid #EAECF0;
      &::before {
          border-bottom: 3px solid #EAECF0;
        }
      .ant-tabs-nav-wrap {
        height: 60px;
      }
    }
  }
  //active-line color
  .ant-tabs-ink-bar {
    border-radius: 3px;
    background: linear-gradient(90deg, #6595f3 0%, #b771c2 48%, #ff3a9b 99.5%);
  }

  .top-rated-wrapper {
    border: 1px solid @gray4;
    border-radius: 4px;
    padding: 23px 22px;
    height: 134px;
    // min-width: 400px;

    .logo-wrapper {
      border: 1px solid @gray4;
      border-radius: 12px;
      height: 100%;
      width: 91px;
    }
  }

  .divider-fullwidth {
    margin-top: 53px;
    margin-bottom: 0;
    width: calc(100% + 322px);
    margin-left: -161px;
    @media (max-width: 1470px) {
      width: calc(100% + 12vw);
      margin-left: -6vw;
    }
  }

  .overview-block {
    &:first-child:not(.no-border) {
      border-right: 1px solid @gray4;
    }
    &.right {
      padding-left: 136px;
    }

  }
  .overview-text {
    text-align: justify;
    color: @mainDark;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 162%;
    padding-right: 114px;
    padding-bottom: 46px;
  }

  .seller-text {
    font-size: 16px;
    &.bold {
      font-weight: 700;
    }
  }

  .pricing-item {
    height: 260px;
    border-radius: 3px;
    border: 1px solid @gray4;
    .gray-block {
      background: @gray4;
      height: 63px;
      font-size: 22px;
      font-weight: 600;
    }
  }

  .features-item {
    padding-top: 43px;
  }
}