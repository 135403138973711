@import '../../../styles/utils/variables.less';

.ant-dropdown {
  top: 77px !important;
}

.ant-layout-header.header-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 113px;
  background: @white;
  border-bottom: 1px solid @gray4;

  .header-middle-part {
    width: 64%;
    display: flex;
    justify-content: center;
    align-items: center;

    .menu-item {
      height: 34px;
      border-radius: 50px;
      padding: 0 12px;
      font-size: 14px;
      color: @mainDark;
      margin-right: 1vw;
      border: 1px solid transparent;

      svg {
        height: 8px;
      }
      &:hover {
        border: 1px solid #3d3960;
        svg {
          transform: rotate(180deg);
        }
        // .anticon.anticon-down {
        //   > svg {
        //     transform: rotate(180deg);
        //   }
        // }
      }
      .anticon.anticon-down {
        width: 10px;
        height: 10px;
        > svg:hover {
          transform: rotate(180deg);
        }
      }
    }

    .link-item {
      display: flex;
      width: fit-content;
      min-width: 60px;
      align-items: center;
      // height: 34px;
      border-radius: 50px;
      padding: 6px 12px;
      font-size: 14px;
      color: @mainDark;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid #3d3960;
        cursor: pointer;
        // transition: border-color 1.4s !important;
      }
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .header-right-part {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

  }
}
