// @import '../../../styles/theme/theme';
// @import '../../../styles/base/animation';
// @import '../../../styles/base/ui-kit';
@import '../../../styles/utils/variables.less';

.global-wrap {

  // &.theme-light {
  //   // background-color: #54468D !important;
  //   background-color: #F6F8FC !important;
  // }

  // &.theme-dark {
  //   background-color: @background;
  // }

  // .main-container {
  //   display: flex;
  //   flex-flow: row nowrap;
  //   justify-content: stretch;
  //   align-items: stretch;
  //   max-width: 1920px;
  //   width: 100%;
  //   border: 1px solid red;
  //   //height: calc(100vh - 138px);
  //   //height: calc(var(--app-height) - 138px);
  //   overflow: hidden;

  //   @media @viewport-mobile {
  //     //height: calc(100vh - 62px);
  //     //height: calc(var(--app-height) - 62px);
  //   }
  // }

  .site-layout{
    background: @white;
    margin: 0 auto;
    max-width: 1920px;
    width: 100%;
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: stretch;
    // align-items: stretch;
  }

  .main-content {
    //height: calc(100vh - 138px);
    //height: calc(var(--app-height) - 138px);
    flex: 1;
    overflow: auto;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 63px 9% 41px 9%;
    // padding: 63px 160px;
    // border-radius: 0px 0px 0px 22px;
    // background: #F9FAFB;
    // border: 1px solid red;
    &:has(> .company-page) {
      padding: 49px 161px;
      @media (max-width: 1470px) {
        padding: 49px 6vw;
      }
      // @media (max-width: 800px) {
      //   padding: 49px 60px;
      // }
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    @media @viewport-mobile {
      //height: calc(100vh - 62px);
      //height: calc(var(--app-height) - 62px);
      flex: 1 0 auto;
    }

    .suspense-spin-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}



// .ant-layout-header.header {
//   position: sticky;
//   top: 0;
//   z-index: 10;
//   width: 100%;
//   height: 124px;
//   padding: 32px 32px;
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: flex-start;
//   justify-content: space-between;
//   background: #FFFFFF;
//   line-height: 1;
//   transition: 0.75s ease-in-out;
//   border-radius: 22px 0px 0px 0px;

//   &.hidden{
//     top: -140px;
//   }

//   @media @viewport-mobile {
//     height: 82px;
//     padding: 10px 20px 10px;
//   }


//   .header_right-part {
//     display: flex;
//     flex-flow: row nowrap;
//     justify-content: flex-end;
//     align-items: center
//   }


//   .ant-dropdown-trigger {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     height: 48px;

//     & + div {
//       right: 0 !important;
//       top: 100% !important;

//       .ant-dropdown {
//         top: 100% !important;
//       }
//     }
//   }
// }
