.custom-menu {
  .ant-dropdown-menu {
    width: 335px;
    min-height: 256px;
    padding: 24px 24px;
    font-size: 18px;
    line-height: 24px;
    background: #FFFFFF;

    border: 1px solid #E2E7F0;
    box-shadow: 0 3px 15px rgba(22, 21, 21, 0.1);
    border-radius: 9px;

    .ant-dropdown-menu-item{
      text-transform: capitalize;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      color: #000000;

      &:hover {
        opacity: 0.9;
        color: #E10252;
      }

      &-divider{
        background-color: #000000;
        margin: 24px 0;
      }
    }

    &_theme-switcher {
      margin: 22px 0 30px;
    }

    .user_logout {
      cursor: pointer;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      color: #000000;

      &:hover {
        opacity: 0.9;
        color: #E10252;
      }
    }
  }
}
