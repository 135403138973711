.trans {
  transition: all 0.3s ease;
}

@keyframes growUp {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes border-pulsate {
  0% {
    border-color: #237cfb;
  }
  50% {
    border-color: #f2f3f8;
  }
  100% {
    border-color: #237cfb;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes pulseBig {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  from {
    bottom: -250px;
  }
  to {
    bottom: 0;
  }
}

@keyframes mini-slide {
  from {
    bottom: -224px;
  }
  to {
    bottom: 0;
  }
}

@keyframes slide-modal {
  0% {
    transform: translateY(100%) scale(1);
    bottom: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    bottom: 0;
  }
}

@keyframes slide-height {
  0% {
    height: 0;
  }
  100% {
    height: 230px;
  }
}

@keyframes marquee-infinite {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
