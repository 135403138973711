:root {
  --app-height: 100%;
}
//@import '~antd/dist/antd.less';
@import '../styles/theme/theme';
@import '../styles/utils/variables';

/* Layout */
body,
html {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  --antd-wave-shadow-color: transparent;
  color: @main;
  margin: 0;
}

a,
a:focus {
  color: @blue500;
}

a:hover,
a:active {
  color: @blue400;
}

svg {
  transition: fill 0.3s ease, opacity 0.3s ease;
}

/*.ant-input,
input {
  caret-color: @blue500 !important;
}*/

.main-layout {
  width: 100%;
  height: 100vh;
  height: var(--app-height);
}

.ant-btn{
  font-weight: 600;

  &-primary{
   color: #F6F8FC;
  }
}

.ant-select {
  &:focus-within .ant-select-selector {
    box-shadow: @shadow-xs !important;
  }
}

.ant-input {
  &:focus {
    box-shadow: @shadow-xs !important;
  }
}

.search-form {
  .ant-select.ant-select-in-form-item{
    .ant-select-selector {
      // border-right: 1px solid #DEDEE4 !important;
      // border: none;
      // border: 1px solid #DEDEE4 !important;
      &:hover {
        // border-right: 1px solid #DEDEE4 !important;
      }
    }
  }
}

// .ant-table-column-sorter{
//  display: none;
// }

.dark-blue-button {
  color: @white;
  background-color: @mainDark;
  width: 160px;
  padding: 15px 20px;
  height: 56px;
  border-radius: 50px;
  border-color: @mainDark;
  > div {
    font-size: 16px;
    font-weight: 400;
  }
  &:hover {
    color: @mainDark !important;
    background-color: @white;
    border-color: @mainDark !important;
    .ant-row {
      .icon{
        &-getintouch {
            path {
              fill: @mainDark;
            }
            circle {
              stroke: @mainDark;
            }
        }
      }
    }
  }
}
