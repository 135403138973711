@import '../../../styles/utils/variables';
@import '../../../styles/base/animation';
@import '../../../styles/theme/theme';

.login-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  align-items: center;
  padding-top: 11.5vh;
  background: #F6F8FC;

  @media @viewport-mobile {
    padding-top: 30px;
  }
}

.login-card {
  width: 100%;
  max-width: 526px;
  padding: 55px 60px 60px;
  margin-top: 11.5vh;

  @media @viewport-mobile {
    padding: 25px 20px 30px;
    margin-top: 10vh;
  }

  border: 1px solid #E2E7F0;
  box-shadow: 0 3px 15px rgba(22, 21, 21, 0.1);
  border-radius: 9px;

  .error-text {
    height: auto;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: @red500;
    margin: 32px 0 12px;

    &_empty {
      min-height: 60px;
      margin: 0;
    }
  }

  .ant-card-body {
    padding: 0 0;
  }
}
