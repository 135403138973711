.not-found{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.not-found img{
  position: absolute;
  transform: translate(-50%, 0);
  top: 40px;
  left: 50%;
  cursor: pointer;
}

.not-found-content {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.not-found-content > * {
  width: 400px;
}

.not-found-content > div {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 120px;
  line-height: 146px;
  margin-bottom: 32px;
}

.not-found-content > p {
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 0;
}

.not-found-content > p > a {
  text-decoration-line: underline;
}

@media (max-width: 1151px) {
  .not-found {
    justify-content: center;
    align-items: flex-start;
  }

  .not-found-content {
    width: auto;
    align-items: center;
    text-align: center;
    margin-top: 160px;
  }

}

@media (max-width: 767px) {
  .not-found img{
    width: 24px;
    top: 16px;
  }
  .not-found-content {
    margin-top: 184px;
  }

  .not-found-content > * {
    width: 264px;
  }

  .not-found-content > div {
    font-size: 80px;
    line-height: 96px;
    margin-bottom: 24px;
  }

  .not-found-content > p {
    font-size: 16px;
    line-height: 24px;
  }
}
