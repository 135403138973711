@import '../../../styles/theme/theme';
@import '../../../styles/utils/variables';
@import '../../../styles/base/ui-kit';

.ant-notification-notice.custom-notification {
  padding: 16px;
  min-width: 452px;
  width: fit-content;

  .with-theme({
    background-color: @vars[color-white-to-dark];
  });

  .ant-notification-notice-with-icon {
    margin: 0;
    min-height: 48px;
  }

  .icon-notification-success {
    rect {
      .with-theme({
        fill: @vars[green-100-to-900];
      });
    }
  }

  .icon-notification-warning {
    rect {
      .with-theme({
        fill: @vars[yellow-100-to-900];
      });
    }
  }

  .ant-notification-notice-message {
    line-height: 48px;
    margin-left: 72px;
    margin-bottom: 0;

    font-size: 16px;
    letter-spacing: -0.18px;

    span {
      flex: 1 1;
    }

    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  }

  .ant-notification-notice-close {
    height: 24px;
    right: 16px;
  }

  @media @viewport-mobile {
    min-width: 100% !important;

    .ant-notification-notice-message {
      line-height: 24px;
    }
  }
}
