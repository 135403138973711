@fontFamily: 'Inter', sans-serif;

@border-radius: 4px;

@main: #667085;

@white: #fff;
@black: #000;
@dark: #0d1a2f;


//dataforest
@gray3: #CECED7;
@gray4: #DEDEE4;
@mainDark: #0C0838;
@lineDark: #3D3960;
@link: #DC459F;

@shadow-xs: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;

//Sales
@Sales50: #F9FAFB;
@Sales100: #F2F4F7;
@Sales200: #EAECF0;
@Sales300: #D0D5DD;
@Sales400: #98A2B3;
@Sales500: #667085;
@Sales600: #475467;
@Sales700: #344054;
@Sales800: #1D2939;
@Sales900: #101828;


/* BG/Dark */
@background: #0d1a2f;
@darkOffsetBottom: linear-gradient(
  360deg,
  #0d1a2f 15%,
  rgba(13, 26, 47, 0) 100%
);
@darkOffsetTop: linear-gradient(360deg, #0d1a2f 15%, rgba(13, 26, 47, 0) 100%);
@blackDropShadow: -20px 20px 55px rgba(107, 107, 107, 0.2);

/* ----  Neutral -------*/
@Neutral50: #F9FAFB;
@Neutral100: #F2F4F7;
@Neutral200: #EAECF0;
@Neutral300: #D0D5DD;
@Neutral400: #98A2B3;
@Neutral500: #667085;
@Neutral600: #475467;
@Neutral700: #344054;
@Neutral800: #1D2939;
@Neutral900: #101828;

@neutralOffsetBottom: linear-gradient(
  360deg,
  #fff 15%,
  rgba(255, 255, 255, 0) 100%
);
@neutralOffsetTop: linear-gradient(
  360deg,
  #fff 15%,
  rgba(255, 255, 255, 0) 100%
);

@bottomOffsetWhite: linear-gradient(360deg, #FFFFFF 15.22%, rgba(255, 255, 255, 0) 85.94%);
@bottomOffsetWhiteOther: linear-gradient(360deg, #FFFFFF 65.22%, rgba(255, 255, 255, 0) 95.94%);
@bottomOffsetDark: linear-gradient(360deg, #0D1A2F 15.22%, rgba(13, 26, 47, 0) 85.94%);
@bottomOffsetDarkOther: linear-gradient(360deg, #0D1A2F 65.22%, rgba(13, 26, 47, 0) 95.94%);

/* ----  Blue -------*/
@blueLight: #F2F6FF;
@bluePrimary: #225AEA;
@blueDepressed: #1448CB;
@blueDeep: #072F94;
@blueDark: #000E58;

/* ----  Blue -------*/
@blue: #237cfb;
@blue100: #f7faff;
@blue200: #bbd7ff;
@blue300: #73acfd;
@blue400: #4b94fc;
@blue500: #237cfb;
@blue600: #0856c6;
@blue700: #063d8c;
@blue800: #0b2953;
@blue900: #0f2543;
@blueDropShadow: -20px 20px 55px rgba(13, 77, 167, 0.2);
@blueDropShadow1: -20px 10px 35px rgba(13, 77, 167, 0.2);
@blue_light: rgba(29, 121, 251, 0.15);

/* ----  Red -------*/
@red: #ff4b40;
@red100: #fff8f7;
@red200: #ffc3bf;
@red400: #ff736a;
@red500: #ff4b40;
@red600: #c63a31;
@red700: #8d2923;
@red800: #551915;
@red900: #411916;
@redDropShadow: -20px 20px 50px rgba(221, 54, 42, 0.12);
@redDropShadow1: -20px 10px 35px rgba(221, 54, 42, 0.12);

/* ----  Pink -------*/
@pink100: #fff9fc;
@pink200: #ffdeee;
@pink400: #ffaed5;
@pink500: #ff83bf;
@pink600: #ca5d9e;
@pink700: #974680;
@pink800: #652e5a;
@pink900: #482641;
@pinkDropShadow: -20px 20px 50px rgba(255, 131, 191, 0.21);
@pinkDropShadow1: -20px 10px 35px rgba(255, 131, 191, 0.21);

/* ----  Green -------*/
@green: #1ae784;
@green100: #f2fff9;
@green200: #b8ffdd;
@green400: #61f2ac;
@green500: #1ae784;
@green600: #0c9955;
@green700: #146b41;
@green800: #084d2c;
@green900: #0e3c26;
@greenDropShadow: -20px 20px 55px rgba(26, 231, 132, 0.2);
@greenDropShadow1: -20px 10px 35px rgba(26, 231, 132, 0.2);

/* ----  Purple -------*/
@purple100: #fbf8ff;
@purple200: #ead4ff;
@purple400: #b26ef5;
@purple500: #9b41f5;
@purple700: #551e8c;
@purple600: #7325bf;
@purple800: #49256d;
@purple900: #482641;
@purpleDropShadow: -20px 20px 55px rgba(155, 65, 245, 0.2);
@purpleDropShadow: -20px 10px 35px rgba(155, 65, 245, 0.2);

/* ----  Brown -------*/
@brown100: #fff6ee;
@brown200: #f1d5ba;
@brown500: #db9652;
@brown400: #e2ab75;
@brown700: #835a31;
@brown600: #af7842;
@brown800: #583c21;
@brown900: #342517;
@brownDropShadow: -20px 20px 55px rgba(219, 150, 82, 0.2);
@brownDropShadow1: -20px 10px 35px rgba(219, 150, 82, 0.2);

@purpleDropShadow: -20px 20px 55px rgba(155, 65, 245, 0.2);
@purpleDropShadow1: -20px 10px 35px rgba(155, 65, 245, 0.2);

/* ----  Yellow -------*/
@yellow: #ffcc48;
@yellow900: #362c13;
@yellow800: #554418;
@yellow700: #8d7128;
@yellow600: #c69e38;
@yellow400: #ffd770;
@yellow500: #ffcc48;
@yellow300: #ffe299;
@yellow200: #ffeec2;
@yellow100: #fffcf3;
@yellowDropShadow: -20px 20px 50px rgba(186, 145, 36, 0.21);
@yellowDropShadow1: -20px 10px 35px rgba(186, 145, 36, 0.21);

/* ---- Media ----*/
@mobile: 768 - 1;
@screen-768-min: 768;

@desktop: 1920 - 10000;
@laptop: 1440 - 1919;

@viewport-mobile: e('only screen and (max-width: @{mobile}px)');
@viewport-768: e('only screen and (min-width: @{screen-768-min}px)');

@viewport-desktop: e('only screen and (min-width: @{desktop}px)');
@viewport-laptop: e('only screen and (min-width: @{laptop}px)');
