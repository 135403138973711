@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

@keyframes isOpened {
  from {
    opacity: 0;
    width: 0;
    display: none;
  }

  to {
    opacity: 1;
    width: 100%;
  }
}

.main-sidebar {
  position: sticky;
  top: 0;
  bottom: 0;
  transition: width 0.3s ease;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  height: 100vh;
  height: var(--app-height);
  width: 271px;
  flex: 0 0 auto;
  padding: 24px 16px 24px;

  background: #54468D;

  .logo-wrapper{
    width: 48px;
    height: 48px;
    //border: 1px solid #0B4678;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;
  }

  &.small-sidebar {
    width: 80px;
    .sidebar-link {
      min-width: 48px;
      justify-content: center;
      &_text {
        display: none;
      }
    }
  }

  .sidebar-link.selected{
    transition: background-color 0s, border-color 0s, box-shadow .3s ease!important;
    // background: transparent;
    background: #4a398e;
    // border: 1px solid #e8317b;
    border: 1px solid #f6f8fc;
    // background: #2a577d;

    svg{
      path{
        stroke: #FFFFFF;
      }
    }
  }

  // add class to sidebar
  @media @viewport-mobile {
    &.closed-sidebar {
      border: none !important;
      width: 0;
      padding: 0;
      overflow: hidden;
    }

    &.opened-sidebar:not(.small-sidebar) {
      width: 234px;
    }
  }

  &_wrap {
    flex: 1;
  }

  &_reduce-size {
    display: block;
    margin: 0 0 10px;
    border: none;
    outline: none;
    cursor: pointer;
    line-height: 48px !important;
    padding: 0 12px;
    border-radius: 6px;
    background-color: transparent;
    transform: rotate(0);
    .button-text();
    // .trans();

    &.small-sidebar {
      svg {
        transform: rotate(180deg);
      }
    }

    &:hover {
      opacity: 0.8;

      .icon-chevron-left {
        .with-theme({
          fill: @vars[sidebar-link-active];
        });
      }
    }
  }

  .sidebar-link {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 0 10px;
    cursor: pointer;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 12px;
    border-radius: 6px;
    border: 1px solid #8A7CC3;
    // border: 1px solid #0B4678;

    // background: #0B4678;
    background: transparent;

    .button-text();
    // .trans();

    &_text {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 12px;

      color: #D0D5DD;

    }

    &_icon {
      flex: 0 0 auto;
    }

    &:not(.disabled):hover {
      .sidebar-link_icon {
        &.icon-home,
        &.icon-settings {
          .with-theme({
            stroke: @vars[sidebar-link-active];
          });
        }
      }
    }

    &:not(.disabled):hover,
    &.selected {
      .sidebar-link_text {
        color: #FFFFFF;
      }
      // opacity: 0.8;
    }

    &.disabled {
      cursor: not-allowed;
      .sidebar-link {
        &_icon {
          .with-theme({
            path{
              stroke: @vars[neutral-200-to-800];
              &:last-of-type{
                fill: @vars[neutral-200-to-800] !important;
              }
            }
            rect{
              fill: @vars[neutral-200-to-800];
            }
          });
        }

        &_text {
          .with-theme({
            color: @vars[neutral-200-to-800];
          });
        }

        &_mark {
          border-radius: 8px;
          padding: 4px 8px;
          margin-left: 8px;
          background: @blue100;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: @blue500;
          text-transform: uppercase;

          .with-theme({
            background-color: @vars[blue-100-to-900];
          });
        }
      }
    }
  }
}
