@import '../../styles/theme/theme';
@import '../../styles/base/animation';

.icon {
  .trans();
  &-btn{
    cursor: pointer;
  }
  &-user-default {
    vertical-align: 0;
  }
  &.icon-notification-delete {
    rect {
      .with-theme({
        fill: @vars[red-100-to-900];
      });
    }
  }

  // &-getintouch {
  //   &:hover {
  //     path {
  //       fill: black;
  //     }
  //     circle {
  //       stroke: black;
  //     }

  //   }
  // }

  &-notification {
    &-outline {
      .with-theme({
        stroke: @vars[notif-fill-color];
        fill: none;
      });
    }

    &-bold {
      .with-theme({
        fill: @vars[notif-fill-color];
      });
    }

    &--disabled {
      .with-theme({
        stroke: @vars[neutral-200-to-800];
      })
    }
  }

  &-chat {
    .with-theme({
      stroke: @vars[notif-fill-color];
      fill: none;
    });
  }

  &-empty-warning {
    .with-theme({
      fill: @vars[notif-empty-icon-color];;
    });
  }

  &-menu {
    .with-theme({
      fill: @vars[menu-fill];;
    });
  }

  &-chevron-left {
    vertical-align: -8px;
    .with-theme({
      fill: @vars[sidebar-fill-outline];
    });
  }

  &-descend {}

  &-ascend {
    transform: rotate(180deg);
  }

  &-chevron_up.rotate{
    transform: rotate(180deg);
  }


}
