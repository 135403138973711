@import '../../../../styles/utils/variables';


.ant-modal.banner-modal {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-close {
    color: #d9d9d9;
  }
}

.left-part {
  padding: 44px 51px;
}
.right-part {
  background:url(../../../Images/catalog-page/modal_bg.png);
  background-size: 103%;
  background-repeat:no-repeat;
  padding: 48px 66px;

}
.gradient {
  background: linear-gradient(52deg, #3da3fc 0%, #a685d2 30.77%, #fc1d7f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-form {
  .ant-input {
    border: none;
    border-bottom: 1px solid @gray3;
    background-color: transparent;
    border-radius: 0;
    padding-left: 0;
  }

  .ant-form-item-label {
    padding: 0;
  }

}