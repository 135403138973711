@import '../../../styles/utils/variables';

.catalog-page {
  color: @mainDark;

  .breadcrumb-item {
    font-size: 16px;
    &.uderline {
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .ant-typography.main-title {
    font-size: 48px;
    font-weight: 400;
    margin-top: 18px;
    margin-left: -3px;
    margin-bottom: 11px;
    &.more {
      margin-bottom: 0px;
    }
  }

  .text-gradient {
    display: block;
    font-size: 21px;
    margin-bottom: 26px;
    background: var(--dataforest-main-gradient, linear-gradient(52deg, #3DA3FC 0%, #A685D2 56.77%, #FC1D7F 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .select-wrapper {
    position: relative;
    // border: 1px solid @gray4;
    // border-radius: 12px;
    // height: 70px;
  }
  .select-group-wrapper {
     border: 1px solid @gray4;
    border-radius: 12px;
  }

  .line {
    width: 175px;
    position: absolute;
    border-top: 1px solid @gray4;
    top: 35px;
    &-left {
      left: -175px;
    }
    &-right {
      right: -175px;
    }
  }

  .company-wrapper {
    margin-top: 20px;
    border: 1px solid @gray4;
    min-height: 300px;
    border-radius: 12px;
    cursor: pointer;

    .company-name {
      display: inline-block;
      font-weight: 300;
      font-size: 22px;
      margin-top: 75px;
    }

    .fst-col-wrapper {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      padding: 46px 26px;
      border-right: 1px solid #DEDEE4;
    }
    .second-col-wrapper {
      display: flex;
      flex-direction: column;
      padding: 23px 54px;
    }

    .list-item {
      color: @lineDark;
      margin-top: 6px;
      line-height: 24px;
      &::marker {
        width: 6px;
        height: 6px;
        color: @gray3;
        margin-right: 4px;
      }
    }
  }

  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    font-size: 20px;
  }

  .pagination-wrapper {
    display: flex;
    position: relative;
  }

  .ant-pagination {
    .ant-pagination-item {
      border-radius: 50px;
      border-color: @gray4;
    }
    .ant-pagination-item-active {
      border: 1px solid @mainDark;
      > a {
        color: @mainDark !important;
      }
    }
    .ant-pagination-prev {
      border: 1px solid @gray4;
      border-radius: 50px;
      position: absolute;
      right: 40px;
      &:hover {
        .ant-pagination-item-link {
          border-radius: 50px;
        }
      }
    }
    .ant-pagination-next {
      border: 1px solid @gray4;
      border-radius: 50px;
      position: absolute;
      right: 0;
      &:hover {
        .ant-pagination-item-link {
          border-radius: 50px;
        }
      }
    }
    // pagination-next:hover .ant-pagination-item-link

  }

  .publication-wrapper {
    cursor: pointer;
    // display: flex;
    // flex-direction: column;
  }

  .ant-btn.button-link-underline {
    color: @link;
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
    > span {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }


  // .foto-box {
  //   // background-image: url('../../Images/example1.jpg');
  //   background-size: 100%;
  //   // background-position: 52%;
  // }

  // .anticon.anticon-close-circle {
  //   border: 1px solid red;
  //   // margin-right: 5px !important;
  //   // vertical-align: -0.125em;
  //   position: absolute;
  //   // right: 7px;
  //   .close-circle {
  //     width: 1,1rem;
  //     height: 1,1rem;
  //   }
  // }

}